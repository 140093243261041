<template>
  <div class="votingDetail_container">
    <ItemScroll>
      <div>
        <div class="votingDetail_title">
          分享课题: {{ data.courseware_name }}
        </div>
        <div class="votingDetail_card">
          <div class="votingDetail_card_block">
            <div class="votingDetail_card_block_card">
              <div class="votingDetail_card_left">
                <div class="doctor_name">专家: {{ data.doctor_name }}</div>
                <div class="doctor_hospital">
                  医院: {{ data.doctor_hospital }}
                </div>
                <div class="vote_number">当前点赞数: {{ data.vote_count }}</div>
                <!-- <div class="rank">
                  当前排名: <span>{{ data.rank }}</span>
                </div> -->
              </div>
              <div class="votingDetail_card_right">
                <img :src="data.profile" class="avater" />
                <!-- <img
                  v-if="data.rank === 1 && data.vote_count !== 0"
                  :src="require('@/assets/images/patientShare/trophy.png')"
                  class="trophy"
                /> -->
              </div>
            </div>
            <div class="votingDetail_handle_vote_block">
              <div
                class="votingDetail_handle_vote"
                :class="{
                  votingDetail_handle_vote_disabled:
                    !isCanVote() || isDisabled(),
                }"
                @click="handleVote(data)"
              >
                我要点赞
              </div>
            </div>
          </div>
        </div>
        <!-- 播放器 -->
        <div class="votingDetail_play_block">
          <video
            :src="data.share_url"
            width="100%"
            ref="videoRef"
            @click="player()"
            @ended="playEnd"
            controls
            controlslist="nodownload"
          ></video>
          <div @click="player()" v-if="!isPlay">
            <div class="play_module" ref="moduleRef"></div>
            <img ref="posterRef" class="play_posterImg" :src="data.cover" />
            <!-- 播放按钮 -->
            <img
              class="votingDetail_play_icon"
              ref="playRef"
              :src="require('@/assets/images/patientShare/play.png')"
            />
          </div>
        </div>
        <div class="votingDetail_play_go_back">
          <el-button
            class="return_btn"
            size="mini"
            icon="el-icon-arrow-left"
            @click="handleGoBack"
            >返回主页</el-button
          >
        </div>
        <div class="detail_prompt_info">
          <p>
            本项目中会传递医药前沿信息、研究进展和科学信息交流，仅供医疗卫生专业人士访问、参考及学习，请确保您的身份是医疗卫生专业人士，否则请退出。视频未经讲者允许，禁止任何形式转载。
          </p>
        </div>
      </div>
    </ItemScroll>
  </div>
</template>

<script>
import { ElButton } from "element-plus";
import weixin from "@/common/weixin";
import ItemScroll from "@/components/unit/itemScroll.vue";

export default {
  data() {
    return {
      defaultAvater: require("@/assets/images/patientShare/avater.png"),
      voteStatus: false,
      data: {},
      isPlay: false,
      count: 0,
      voteList: [],
    };
  },
  components: {
    ElButton,
    ItemScroll,
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "我要点赞";
  },
  created() {
    this.loadData();
  },
  methods: {
    isCanVote() {
      return (
        this.$tools.getTime(this.data.start_time) < Date.now() &&
        this.$tools.getTime(this.data.end_time) > Date.now()
      );
    },
    player() {
      if (!this.isPlay) {
        this.$refs.videoRef.play();
        this.isPlay = true;
      } else {
        this.$refs.videoRef.pause();
      }
    },
    playEnd() {
      this.isPlay = false;
      this.$refs.videoRef.currentTime = 0;
      this.$refs.posterRef.style.display = "block";
      this.$refs.moduleRef.style.display = "bolck";
    },
    initParams() {
      let params = {
        title: `【“产科速讯”文献病例分享会】${this.data.courseware_name}`,
        desc: `我是${this.data.doctor_name}医生,来自${this.data.doctor_hospital}。\n快来为我点赞加油吧!`,
        url: "",
        logo: this.data.profile,
      };
      //分享
      weixin.shareMessage(params);
    },
    isDisabled() {
      if (this.count < this.data.vote_people_max_num) {
        if (this.voteList.includes(this.data.id)) return false;
        if (this.voteList.length < this.data.vote_people_num) return false;
      }
      return true;
    },
    handleGoBack() {
      this.$router.push("/patientShare/challenge");
    },
    //点赞
    async handleVote(item) {
      if (this.timer && Date.now() - this.timer < 1000) return;
      this.timer = Date.now();
      if (this.internalVote !== 1) {
        if (this.$tools.getTime(this.data.start_time) > Date.now())
          return this.$tips.error("本期点赞未开始");
        if (this.$tools.getTime(this.data.end_time) < Date.now())
          return this.$tips.error("本期点赞已结束");
      }
      if (this.isDisabled()) {
        if (this.voteList.length >= this.data.vote_people_num)
          return this.$tips.error(
            "您今日点赞次数已用完，请明天再为喜爱的专家点赞！"
          );
        return this.$tips.error("3票已投完,请为其他专家点赞");
      }
      let url = this.$tools.getURL(this.$urls.patientShare.vote);
      let res = await this.$axios.post(url, item);
      if (res.errcode) return;
      this.count += 1;
      this.data.vote_count += 1;
      this.$tips.success("点赞成功");
    },

    async loadMyVote() {
      let url = this.$tools.getURL(this.$urls.patientShare.myVote, {
        company_id: this.data.company_id,
        period: this.data.period,
        project_id: this.$tools.getCompany("patientShare", "projectId"),
        openid: this.$tools.getCookie("openid"),
      });
      let res = await this.$axios.get(url);
      let list = res.data.filter(
        (item) =>
          this.$tools.getTime(item.subdate) >=
          this.$tools.getTime(this.$tools.getDate())
      );
      let currentVote = list.find(
        (item) => item.vote_id == this.$route.query.vote_id
      );
      this.count = currentVote ? currentVote.count : 0;
      this.voteList = Array.from(new Set(list.map((x) => x.vote_id)));
      this.$nextTick(() => {
        this.initParams();
      });
    },
    //我的点赞
    async loadData() {
      let query = this.$route.query;
      let url = this.$tools.getURL(this.$urls.patientShare.voteDetail, query);
      let res = await this.$axios.get(url);
      this.data = res.data;
      this.data.vote_count = this.data.vote_count ? this.data.vote_count : 0;
      this.loadMyVote();
    },
  },
};
</script>

<style >
.votingDetail_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f2f2f3;
  padding-top: 2.67vw;
  overflow: hidden;
}
.votingDetail_title {
  margin: 0 4.33vw;
  font-size: 3.2vw;
  color: #80b25f;
  padding: 2.67vw;
  background: #fff;
  word-break: break-all;
  white-space: normal;
  border-radius: 1.33vw;
}
.votingDetail_card {
  margin: 1.33vw 0;
  border-radius: 1.33vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.votingDetail_card_block {
  margin: 5.33vw 0;
  width: 92vw;
  padding: 4vw;
  border: 1px solid #80b25f;
  box-shadow: 0 0 0.52vw 0.5vw rgba(128, 178, 95, 0.5);
  border-radius: 2vw;
  justify-content: space-between;
  align-items: center;
}
.votingDetail_card_block_card {
  display: flex;
}
.votingDetail_card_left {
  height: 21.33vw;
  font-size: 3.2vw;
  margin-right: 1.33vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.votingDetail_card_left .rank span {
  color: #80b25f;
}
.votingDetail_card_right {
  position: relative;
}
.votingDetail_card_right .avater {
  width: 21.33vw;
  height: 21.33vw;
  border-radius: 50%;
  border: 1px solid #80b25f;
  flex: none;
}
.votingDetail_card_right .trophy {
  width: 6.67vw;
  position: absolute;
  top: 0.53vw;
  left: -0.8vw;
}
.votingDetail_play_block {
  position: relative;
  height: 92vw;
  margin: 0 4vw;
}
.votingDetail_play_icon {
  width: 20vw;
  height: 20vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.play_posterImg {
  width: 100%;
  height: 92vw;
  position: absolute;
  top: 0;
  left: 0;
}
.play_module {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 92vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.votingDetail_play_go_back {
  margin: 4vw 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.votingDetail_play_go_back .return_btn {
  width: 35vw;
  border-radius: 20vw;
  background: #80b25f !important;
  color: #fff;
  border: none;
}
.detail_prompt_info {
  vertical-align: middle;
  padding: 2.67vw 5.33vw;
  background: #e5eae2;
  font-size: 2vw;
  flex: none;
  display: flex;
  align-items: center;
}
.detail_prompt_info {
  line-height: 3.5vw;
}
.votingDetail_handle_vote {
  width: 22.4vw;
  height: 6.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #80b25f;
  border-radius: 6.4vw;
}
.votingDetail_handle_vote_disabled {
  background: #909499;
}
.votingDetail_handle_vote_block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.votingDetail_container video::-webkit-media-controls-fullscreen-button {
  display: block !important;
  content: "\e719";
}
</style>